.fadeIn {
  animation: fade 7s ease-in-out;
  transition: all 7s ease-in-out;
  z-index: 9;
}
.fadeOut {
  animation: fadeOut 2s ease-in-out;
  transition: all 2s ease-in-out;
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes gradientBg {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinHoritanzal {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(1080deg);
  }
}

.animationDelay1 {
  animation-delay: 0.15s;
}
.animationDelay2 {
  animation-delay: 0.3s;
}
.animationDelay3 {
  animation-delay: 0.45s;
}
.animationDelay4 {
  animation-delay: 0.6s;
}
.animationDelay5 {
  animation-delay: 0.75s;
}
.spin {
  animation: spinHoritanzal 1.5s linear;
}
